<template>
  <div>
    <b-row>
      <b-col lg="8">
        <p class="text-green ma-28-semi mb-4 w-100">{{ $t('manual-guide') }}</p>
        <p><strong class="mr-2">{{ $t('step1') }}</strong>{{ $t('manual-guide-2') }}</p>
        <div class="text-center mb-4">
          <img src="/assets/image/blog/manual-1.png" height="312px">
          <p><i>Giao diện chính của website</i></p>
        </div>
        <p><strong class="mr-2">{{ $t('step2') }}</strong>{{ $t('manual-guide-3') }}</p>
        <div class="text-center mb-4">
          <img src="/assets/image/blog/manual-2.png" height="312px">
          <p><i>Thanh chức năng tra cứu thông tin cây xanh hiển thị bên tay trái màn hình</i></p>
        </div>
        <p>{{ $t('manual-guide-4') }}</p>
        <div class="text-center mb-4">
          <img src="/assets/image/blog/manual-3.png" height="312px">
          <p><i>Thanh tìm kiếm giúp bạn tìm kiếm phân loại cây một cách nhanh nhất</i></p>
        </div>
        <p>{{ $t('manual-guide-5') }}</p>
        <ul>
          <li>{{ $t('manual-guide-5-1') }}</li>
          <li>{{ $t('manual-guide-5-2') }}</li>
          <li>{{ $t('manual-guide-5-3') }}</li>
          <li>{{ $t('manual-guide-5-4') }}</li>
        </ul>
        <p>{{ $t('manual-guide-6') }}</p>
        <div class="text-center mb-4">
          <img src="/assets/image/blog/manual-3.png" height="312px">
          <p><i>{{ $t('manual-guide-7') }}</i></p>
        </div>
        <p>{{ $t('manual-guide-8') }}</p>
        <div class="text-center mb-4">
          <img src="/assets/image/blog/manual-4.png" height="312px">
          <p><i>{{ $t('manual-guide-9') }}</i></p>
        </div>
        <p>{{ $t('manual-guide-10')  }}</p>
        <ul>
          <li>{{ $t('manual-guide-10-1')  }}</li>
          <li>{{ $t('manual-guide-10-2')  }}</li>
          <li>{{ $t('manual-guide-10-3')  }}</li>
        </ul>
        <div class="text-center mb-4">
          <img src="/assets/image/blog/manual-5.png" height="312px">
          <p><i>{{ $t('manual-guide-11') }}</i></p>
        </div>
        <div class="text-center mb-4">
          <img src="/assets/image/blog/manual-6.png" height="312px">
          <p><i>{{ $t('manual-guide-12') }}</i></p>
        </div>
        <p>{{ $t('manual-guide-13') }}</p>
        <div class="text-center mb-4">
          <img src="/assets/image/blog/manual-7.png" height="312px">
        </div>
        <p>{{ $t('manual-guide-15') }}</p>
        <div class="text-center mb-4">
          <img src="/assets/image/blog/manual-8.png" height="312px">
        </div>
        <p>{{ $t('manual-guide-16') }}</p>
        <div class="text-center mb-4">
          <img src="/assets/image/blog/manual-9.png" height="312px">
        </div>
        <p>{{ $t('manual-guide-17') }}</p>
        <div class="text-center mb-4">
          <img src="/assets/image/blog/manual-10.png" height="312px">
        </div>
        <div class="text-center mb-4">
          <img src="/assets/image/blog/manual-11.png" height="312px">
        </div>
        <p>{{ $t('manual-guide-18') }}</p>
      </b-col>
      <b-col lg="4">
        <RightMenu />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RightMenu from "@/layout/RightMenu";
export default {
  components: {
    RightMenu,
  },
};
</script>

<style scoped>
.separator {
  background: #e6e8ec;
  height: 1px;
}
.menu-content {
  color: var(--website-neutral-60, #18191a);
  /* Website & tablet/Menu */
  font-size: 14px;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
}
.menu-title {
  color: var(--brand-color, #c71313);
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
}
.right-menu {
  border-radius: 24px;
  border: 1px solid var(--website-gray, #e6e8ec);
  background: var(--website-white, #fff);
  padding: 32px;
}
.font-64 {
  font-size: 64px;
}
.font-24 {
  font-size: 24px;
}
.title-block {
  font-size: 12px;
  font-weight: 300;
  color: #636466;
  width: 75%;
}
.title-secondary {
  font-size: 16px;
  font-weight: 300;
  color: #636466;
  width: 75%;
  margin-bottom: 40px !important;
}
.content-slogan {
  color: var(--website-brand-color, #c71313);
  /* Website & tablet/Header 1 */
  font-size: 48px;
  font-weight: 400;
  line-height: 60px; /* 125% */
  margin-bottom: 40px !important;
}
.content-card {
  border: 1px solid #e6e8ec;
  border-radius: 24px;
  overflow: hidden;
  transition: 500ms;
  cursor: pointer;
}
.content-card p {
  height: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.content-card:hover {
  box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
}
</style>
